h1 {
  font-size: 24px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}
h2 {
  font-size: 19px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 20.9px;
}
h3 {
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 15.4px;
}
p,
body {
  font-family: "Michroma";
  font-size: 22px;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}
blockquote {
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
pre,
code {
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}
a,
a:link,
a:visited,
a:hover,
a:active,
.highlight {
  color: orange;
  font-weight: 600;
}
code {
  background: gainsboro;
}
.icon {
  height: 14px;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: underline;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 680px;
}
nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}
nav ul li:not(:first-child) {
  margin-left: 8px;
}
nav ul li:not(:last-child) {
  margin-right: 8px;
}

.blurp {
  clip-path: polygon(1em 0, 100% 0, calc(100% - 1em) 100%, 0 100%);
  padding: 1em;
  overflow: visible;
  background-color: black;
  color: white;
  width: fit-content;
}

.blurp.small {
  padding: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  clip-path: polygon(0.5em 0, 100% 0, calc(100% - 0.5em) 100%, 0 100%);
}
